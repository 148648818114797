@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&subset=cyrillic,cyrillic-ext,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&subset=cyrillic,cyrillic-ext,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Open Sans', arial;
}

.row {
  margin: 0;
}

.mainTable {
  max-width: 1300px;
}

.mainTable2 {
  max-width: 800px;
}

header {
  background-color: #f8f9fa;
}

.logo {
  height: 30px;
}

.innerPage {
  margin-top: 75px;
}

.nav-link {
  font-family: 'Open Sans', arial;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 30px !important;
}

.innerPage h1 {
  margin-bottom: 55px;
  font-weight: 600;
}

.innerPageText p {
  font-size: 15px;
  text-align: justify;
}

.innerPageText ul,
.innerPageText ol {
  font-size: 15px;
  text-align: left;
  margin: 0;
}

.loader,
.block {
  display: block;
  width: 100%;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #48a540;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@media screen and (max-width: 1199px) {
  .navbar-toggler {
    padding: 4px 6px;
  }

  .navbar-light .navbar-toggler {
    border-color: #246223;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23246223' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-collapse {
    margin: 15px 0px 10px 0px;
  }

  .nav-link {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .innerPage {
    margin-top: 30px;
  }

  .innerPage h1 {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    max-height: 20px;
  }
  .navbar {
    padding: 8px 0px;
  }
}

.hero img {
  max-width: 100%;
}

.hero {
  position: relative;
}

.heroOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.2;
}

.heroText {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-family: 'Open sans', arial;
  text-shadow: 4px 4px 4px rgb(0 0 0 / 50%);
}

.hero h1 {
  color: #fff;
  font-size: 45px;
}

.hero h1 span {
  font-size: 50px;
  font-weight: 800;
}

.hero img {
  max-width: 100%;
}

.hero {
  position: relative;
}

.heroOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.2;
}

.heroText {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-family: 'Open sans', arial;
  text-shadow: 4px 4px 4px rgb(0 0 0 / 50%);
}

.hero h1 {
  color: #fff;
  font-size: 45px;
}

.hero h1 span {
  font-size: 50px;
  font-weight: 800;
}

@media screen and (max-width: 1350px) {
  .heroText {
    left: 35px;
  }
}

@media screen and (max-width: 800px) {
  .hero h1 {
    font-size: 25px;
  }
  .hero h1 span {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .heroText {
    position: static;
    top: auto;
    -webkit-transform: none;
            transform: none;
    text-align: center;
    padding: 15px 0px;
    text-shadow: none;
  }

  .heroOverlay {
    display: none;
  }

  .hero {
    background: #f2f2f2;
  }

  .hero h1 {
    color: #246223;
  }
}


.carrierImg {
  max-width: 100%;
  max-height: 28px;
}

.carrierSingle {
  padding: 25px 20px;
  margin-bottom: 35px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  color: #212529;
  box-shadow: 6px 6px 29px 8px rgb(239 239 239);
  transition: all 0.1s ease-in-out;
}

.carrierSingle:hover {
  color: #212529;
  text-decoration: none;
  box-shadow: none;
}

.carrierAuto {
  margin-top: auto;
  margin-bottom: auto;
}

.carrierSingleTitle {
  padding: 0px 40px;
}

.carrierSingleTitle h2 {
  font-weight: 600;
  font-size: 30px;
}

.carrierSingleTitle p {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}

.carrierSalary,
.carrierSalary2 {
  display: block;
  background-color: #eaffe4;
  margin: 0px 10px;
  text-align: left;
  padding: 6px 10px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
}

.carrierSalary2 {
  background-color: #ffffc1;
}

.carrierSalary span,
.carrierSalary2 span {
  font-size: 12px;
}

.carrierCity {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}

@media screen and (max-width: 1199px) {
  .carrierSingleTitle {
    padding: 0px 15px;
  }

  .carrierSalary {
    margin: 10px 0px 0px 0px;
  }

  .carrierCity {
    text-align: left;
  }

  .carrierSingleTitle h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 850px) {
  .mainTable2 {
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  .carrierLogo {
    margin-bottom: 15px;
  }

  .carrierSingle {
    text-align: center;
  }

  .carrierSalary,
  .carrierCity {
    text-align: center;
  }

  .carrierCity {
    margin-top: 15px;
  }

  .carrierSingleTitle h2 {
    font-size: 20px;
  }

  .carrierSingle:last-child {
    margin-bottom: 0;
  }
}

.carrierPostInfo {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 10px;
  font-size: 13px;
  box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.12);
}

.carrierPostContainer h1,
.carrierPostContainer h2,
.carrierPostContainer h3,
.carrierPostContainer h4,
.carrierPostContainer h5 {
  /*background-color: #fff;
  padding: 15px;
  border-radius: 10px;*/
  margin: 20px 0px;
  font-weight: 600;
  /*box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.12);*/
}

.carrierForm h1,
.carrierForm h2,
.carrierForm h3,
.carrierForm h4,
.carrierForm h5 {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin: 30px 0px;
  font-weight: 600;
  box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.12);
}

.carrierPostContainer h1 {
  font-size: 20px;
}

.carrierPostContainer h2 {
  font-size: 20px;
}

.carrierPostContainer h3 {
  font-size: 20px;
}

.carrierPostContainer h4 {
  font-size: 20px;
}

.carrierPostContainer h5 {
  font-size: 20px;
}

.carrierForm h3 {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #fff;
}

.carrierForm input[type='text'],
.carrierForm input[type='email'],
.carrierForm input[type='file'] {
  border-radius: 10px;
  font-size: 15px;
  padding: 10px 20px;
  box-shadow: none !important;
  outline: none !important;
}

.carrierForm input[type='text']:focus,
.carrierForm input[type='email']:focus {
  border: 1px solid #4ba941;
}

.carrierForm input[type='file'] {
  color: rgba(0, 0, 0, 0);
}

.carrierForm input[type='file'] {
  padding: 0;
  border-radius: 0px;
}

.carrierForm label {
  font-size: 15px;
}

.carrierForm label span {
  color: red;
}

.carrierForm input[type='submit'],
.carrierForm input[type='submit']:focus {
  border-radius: 15px;
  background-color: #4ba941;
  border: 2px solid #4ba941;
  padding: 10px 25px;
  text-transform: uppercase;
  font-size: 15px;
  box-shadow: none !important;
  outline: none !important;
}

.carrierForm input[type='submit']:hover,
.carrierForm input[type='submit']:active {
  background-color: #fff !important;
  color: #4ba941 !important;
  border: 2px solid #4ba941 !important;
}

.carrierForm .file {
  font-size: 14px;
  margin-bottom: 10px;
}

.fileSuccess {
  color: #1c7430;
}

.carrierPostInfo .col-md-3 {
  padding: 2px 15px;
}

.privacy-policy {
  font-size: 14px;
  margin-bottom: 1rem;
}

footer {
  background-color: #f2f2f2;
  padding: 15px 20px;
  text-align: center;
  margin-top: 40px;
}

.copyright {
  font-size: 14px;
}

