.hero img {
  max-width: 100%;
}

.hero {
  position: relative;
}

.heroOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.2;
}

.heroText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Open sans', arial;
  text-shadow: 4px 4px 4px rgb(0 0 0 / 50%);
}

.hero h1 {
  color: #fff;
  font-size: 45px;
}

.hero h1 span {
  font-size: 50px;
  font-weight: 800;
}

@media screen and (max-width: 1350px) {
  .heroText {
    left: 35px;
  }
}

@media screen and (max-width: 800px) {
  .hero h1 {
    font-size: 25px;
  }
  .hero h1 span {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .heroText {
    position: static;
    top: auto;
    transform: none;
    text-align: center;
    padding: 15px 0px;
    text-shadow: none;
  }

  .heroOverlay {
    display: none;
  }

  .hero {
    background: #f2f2f2;
  }

  .hero h1 {
    color: #246223;
  }
}
