@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&subset=cyrillic,cyrillic-ext,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&subset=cyrillic,cyrillic-ext,latin-ext');

body {
  font-family: 'Open Sans', arial;
}

.row {
  margin: 0;
}

.mainTable {
  max-width: 1300px;
}

.mainTable2 {
  max-width: 800px;
}

header {
  background-color: #f8f9fa;
}

.logo {
  height: 30px;
}

.innerPage {
  margin-top: 75px;
}

.nav-link {
  font-family: 'Open Sans', arial;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 30px !important;
}

.innerPage h1 {
  margin-bottom: 55px;
  font-weight: 600;
}

.innerPageText p {
  font-size: 15px;
  text-align: justify;
}

.innerPageText ul,
.innerPageText ol {
  font-size: 15px;
  text-align: left;
  margin: 0;
}

.loader,
.block {
  display: block;
  width: 100%;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #48a540;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media screen and (max-width: 1199px) {
  .navbar-toggler {
    padding: 4px 6px;
  }

  .navbar-light .navbar-toggler {
    border-color: #246223;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23246223' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-collapse {
    margin: 15px 0px 10px 0px;
  }

  .nav-link {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .innerPage {
    margin-top: 30px;
  }

  .innerPage h1 {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    max-height: 20px;
  }
  .navbar {
    padding: 8px 0px;
  }
}
