.carrierPostInfo {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 10px;
  font-size: 13px;
  box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.12);
}

.carrierPostContainer h1,
.carrierPostContainer h2,
.carrierPostContainer h3,
.carrierPostContainer h4,
.carrierPostContainer h5 {
  /*background-color: #fff;
  padding: 15px;
  border-radius: 10px;*/
  margin: 20px 0px;
  font-weight: 600;
  /*box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.12);*/
}

.carrierForm h1,
.carrierForm h2,
.carrierForm h3,
.carrierForm h4,
.carrierForm h5 {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin: 30px 0px;
  font-weight: 600;
  box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.12);
}

.carrierPostContainer h1 {
  font-size: 20px;
}

.carrierPostContainer h2 {
  font-size: 20px;
}

.carrierPostContainer h3 {
  font-size: 20px;
}

.carrierPostContainer h4 {
  font-size: 20px;
}

.carrierPostContainer h5 {
  font-size: 20px;
}

.carrierForm h3 {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #fff;
}

.carrierForm input[type='text'],
.carrierForm input[type='email'],
.carrierForm input[type='file'] {
  border-radius: 10px;
  font-size: 15px;
  padding: 10px 20px;
  box-shadow: none !important;
  outline: none !important;
}

.carrierForm input[type='text']:focus,
.carrierForm input[type='email']:focus {
  border: 1px solid #4ba941;
}

.carrierForm input[type='file'] {
  color: rgba(0, 0, 0, 0);
}

.carrierForm input[type='file'] {
  padding: 0;
  border-radius: 0px;
}

.carrierForm label {
  font-size: 15px;
}

.carrierForm label span {
  color: red;
}

.carrierForm input[type='submit'],
.carrierForm input[type='submit']:focus {
  border-radius: 15px;
  background-color: #4ba941;
  border: 2px solid #4ba941;
  padding: 10px 25px;
  text-transform: uppercase;
  font-size: 15px;
  box-shadow: none !important;
  outline: none !important;
}

.carrierForm input[type='submit']:hover,
.carrierForm input[type='submit']:active {
  background-color: #fff !important;
  color: #4ba941 !important;
  border: 2px solid #4ba941 !important;
}

.carrierForm .file {
  font-size: 14px;
  margin-bottom: 10px;
}

.fileSuccess {
  color: #1c7430;
}

.carrierPostInfo .col-md-3 {
  padding: 2px 15px;
}

.privacy-policy {
  font-size: 14px;
  margin-bottom: 1rem;
}
