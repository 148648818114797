footer {
  background-color: #f2f2f2;
  padding: 15px 20px;
  text-align: center;
  margin-top: 40px;
}

.copyright {
  font-size: 14px;
}
