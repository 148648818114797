.carrierImg {
  max-width: 100%;
  max-height: 28px;
}

.carrierSingle {
  padding: 25px 20px;
  margin-bottom: 35px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  color: #212529;
  box-shadow: 6px 6px 29px 8px rgb(239 239 239);
  transition: all 0.1s ease-in-out;
}

.carrierSingle:hover {
  color: #212529;
  text-decoration: none;
  box-shadow: none;
}

.carrierAuto {
  margin-top: auto;
  margin-bottom: auto;
}

.carrierSingleTitle {
  padding: 0px 40px;
}

.carrierSingleTitle h2 {
  font-weight: 600;
  font-size: 30px;
}

.carrierSingleTitle p {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}

.carrierSalary,
.carrierSalary2 {
  display: block;
  background-color: #eaffe4;
  margin: 0px 10px;
  text-align: left;
  padding: 6px 10px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
}

.carrierSalary2 {
  background-color: #ffffc1;
}

.carrierSalary span,
.carrierSalary2 span {
  font-size: 12px;
}

.carrierCity {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}

@media screen and (max-width: 1199px) {
  .carrierSingleTitle {
    padding: 0px 15px;
  }

  .carrierSalary {
    margin: 10px 0px 0px 0px;
  }

  .carrierCity {
    text-align: left;
  }

  .carrierSingleTitle h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 850px) {
  .mainTable2 {
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  .carrierLogo {
    margin-bottom: 15px;
  }

  .carrierSingle {
    text-align: center;
  }

  .carrierSalary,
  .carrierCity {
    text-align: center;
  }

  .carrierCity {
    margin-top: 15px;
  }

  .carrierSingleTitle h2 {
    font-size: 20px;
  }

  .carrierSingle:last-child {
    margin-bottom: 0;
  }
}
